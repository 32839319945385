<template>
    <component :is="template">
        <v-responsive class="member-points py-9 px-0 px-md-3">
            <v-layout justify-center><span class="headline mb-8">포인트 내역</span></v-layout>
            <v-divider class="mt-6"/>
            <v-data-table :items="points" :headers="pointsHeaders" :mobile-breakpoint="-1" disable-pagination disable-sort hide-default-footer>
                <v-responsive slot="item.purchases" slot-scope="{item}" min-width="200">
                    <v-layout v-for="purchase in item.purchases" :key="purchase._id" align-center>
                        <v-img :src="purchase?.thumb?.path || purchase?.thumb?.src" max-width="48" max-height="48"></v-img>
                        <span class="mx-2"><b>{{ purchase?.name }}</b></span>
                    </v-layout>
                </v-responsive>
                <template slot="item.status" slot-scope="{item}">
                    <v-layout v-for="purchase in item.purchases" :key="purchase._id" justify-center>
                        <span>{{ purchase?.status }}</span>
                    </v-layout>
                </template>
                <template slot="item.paymentStatus" slot-scope="{item}">
                    <v-layout v-for="purchase in item.purchases" :key="purchase._id" justify-center>
                        <span>{{ purchase?.paymentStatus }}</span>
                    </v-layout>
                </template>
                <span slot="item.totalAmount" slot-scope="{item}">{{ item.totalAmount.format() }} 원</span>
                <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
                <span slot="item.resend" slot-scope="{item}">
                    <v-icon v-if="item.issue?.pins?.length" small @click="resend(item)">mdi-message</v-icon>
                </span>
            </v-data-table>
            <v-divider />
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" class="mt-6" />
        </v-responsive>
    </component>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {

            filter: {},

            page: 1,
            pageCount: 0,
            limit: 10,

            points: [],
            pointsHeaders: [
                { text: "주문번호", value: "orderNo", align: "center", width: 100 },
                { text: "구매상품", value: "remark", align: "center" },
                { text: "주문금액", value: "amount", align: "center", width: 150 },
                { text: "결제상태", value: "remain", align: "center", width: 100 },
                { text: "주문일자", value: "createdAt", align: "center", width: 180 },
            ]
        }
    },
    mounted(){
        if(this.logoff) this.$router.push(`/login`);
        this.init();
    },
    methods: {
        async init(){
            await this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, points } = await api.v1.me.points.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter
            });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.points = points;
        }
    },
    computed: {
        template(){ return () => import(`@/templates/${this.$theme.pages.point}`) },
        
        logoff(){
            return !this.$store.state.accessToken;
        }
    }
}
</script>

<style scoped>
.member-points .title {
    font-size: 22px !important;
    font-weight: 600;
}
.member-points >>> .v-data-table__mobile-row {
    height: auto;
}
</style>